import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
// import instagramIcon from '@iconify/Instagram';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// material
import { makeStyles, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, useMediaQuery } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
//
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill, href: 'https://www.facebook.com/mediio.net' },
  { name: 'Instagram', icon: 'bx:bxl-instagram-alt', href: 'https://www.instagram.com/medigrant_official/' },
  { name: 'Linkedin', icon: linkedinFill, href: 'https://www.linkedin.com/company/mediiio-kr/' },
];

const LINKS = [
  {
    headline: 'Mediio',
    children: [
      { name: 'Home', href: '/' },
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Portfolio', href: PATH_PAGE.portfolio },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'Careers', href: PATH_PAGE.careers },
    ],
  },
  // {
  //   headline: 'Services',
  //   children: [
  //     { name: 'MediGrant', href: PATH_PAGE.services, tabValue: '1' },
  //     { name: 'Dr. KIT', href: PATH_PAGE.services, tabValue: '2' },
  //     { name: 'MediScanner', href: PATH_PAGE.services, tabValue: '3' },
  //     { name: 'MediYo', href: PATH_PAGE.services, tabValue: '4' },
  //     { name: 'MediWidget', href: PATH_PAGE.services, tabValue: '5' },
  //   ],
  // },
  {
    headline: 'Contact',
    children: [
      { name: 'email', href: 'mailto:manager@mediio.net' },
      { name: 'number', href: 'tel: +8228610902' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useLocales();
  // const history = useHistory();
  const navigate = useNavigate();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid container justifyContent={{ xs: 'center', sm: 'space-between' }} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', sm: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} sm={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {t('mainFooter.quote')}
            </Typography>

            {/* <Stack spacing={1.5} direction="row" justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ mt: 5, mb: { xs: 5, md: 0 } }}>
              {SOCIALS.map((social) => (
                <a key={social.name} href={social.link} target="_blank" rel="noreferrer">
                  <IconButton key={social.name} href={social.href} target="_blank" color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                </a>
              ))}
            </Stack> */}
          </Grid>

          <Grid item xs={12} sm={5} md={4} sx={{ mt: { xs: 4, sm: 0 } }}>
            <Stack spacing={5} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {t(`mainFooter.${headline}`)}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={link.name === 'email' || link.name === 'number' ? 'a' : 'button'}
                        href={link.name === 'email' || link.name === 'number' ? link.href : ''}
                        onClick={() => !(link.name === 'email' || link.name === 'number') && navigate({ pathname: link.href, state: { linkName: link.name } })}
                        sx={{ display: 'block', textAlign: { xs: 'center', sm: 'left' } }}
                      >
                        {t(`mainFooter.${link.name}`)}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © MEDIIO 2025 All rights reserved.
        </Typography>
      </Container>
    </RootStyle>
  );
}
